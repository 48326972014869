<template>
  <b-card no-body>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller font-scale="3" animation="cylon" />
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>
      <b-card-body>
        <!-- <div class="d-flex justify-content-between flex-wrap"> -->
        <b-row>
          <b-col cols="4">
            <b-form-select
              id="perPageSelect"
              v-model="rateitem"
              value-field="code"
              text-field="code"
              inline
              :options="rateLimit"
              class="mr-2"
              @input="SelectedRate()"
            />
          </b-col>
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              block
              @click="SaveRate()"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-card-body>

      <b-card-body>
        <div>
          <hr class="my-4" />
          <b-row>
            <!-- 4 -->
            <!-- <b-col cols="12" >
              <b-form-group
                label="4 Up (Thai government)"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                      <label for="">Maximum bet per number 4 Up</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitU4"
                      disabled
                      placeholder="Maximum bet per number 4 Up"
                    />
                  </b-col>
                  <b-col cols="5">
                    <label for="">Pay 4 Up</label>
                    <b-form-input
                      type="number"
                      v-model="PayU4"
                      placeholder="Pay 4 Up"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                    <label for="">Maximum pay 4 Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU4"
                      disabled
                      placeholder="Maximum pay 4 Up"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number 4 Up</label>
                    <b-form-input
                      type="number"
                      v-model="MinU4"
                      disabled
                      placeholder="Minimum bet per round/number 4 Up"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Maximum bet per round/number 4 Up</label>
                    <b-form-input
                      type="number"
                      v-model="MaxU4"
                      disabled
                      placeholder="Maximum bet per round/number 4 Up"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout" >
                    <label for="">Maximum 4 Up</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitU4"
                      placeholder="Maximum 4 Up"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateU4" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Class</label>
                    <b-form-input
                      v-model="StepRateU4.length"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateU4" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
            <!-- 3หน้า -->
            <!-- <b-col cols="12" >
              <b-form-group
                label="3 F (Thai government)"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                      <label for="">Maximum bet per number 3 F</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitF3"
                      disabled
                      placeholder="Maximum bet per number 3 F"
                    />
                  </b-col>
                  <b-col cols="5">
                    <label for="">Pay 3 F</label>
                    <b-form-input
                      type="number"
                      v-model="PayF3"
                      placeholder="Pay 3 F"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                      <label for="">Maximum pay 3 F</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayF3"
                      disabled
                      placeholder="Maximum pay 3 F"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number 3 F</label>
                    <b-form-input
                      type="number"
                      v-model="MinF3"
                      disabled
                      placeholder="Minimum bet per round/number 3 F"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Maximum bet per round/number 3 F</label>
                    <b-form-input
                      type="number"
                      v-model="MaxF3"
                      disabled
                      placeholder="Maximum bet per round/number 3 F"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Maximum 3 F</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitF3"
                      placeholder="Maximum 3 F"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateF3" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayF3"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateF3" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
            <!-- 3หลัง -->
            <!-- <b-col cols="12" >
              <b-form-group
                label="3 B (Thai government)"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                    <label for="">Maximum bet per number 3 B</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitB3"
                      disabled
                      placeholder="Maximum bet per number 3 B"
                    />
                  </b-col>
                  <b-col cols="5">
                    <label for="">Pay 3 B</label>
                    <b-form-input
                      type="number"
                      v-model="PayB3"
                      placeholder="Pay 3 B"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                    <label for="">Maximum pay 3 B</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayB3"
                      disabled
                      placeholder="Maximum pay 3 B"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number 3 B</label>
                    <b-form-input
                      type="number"
                      v-model="MinB3"
                      disabled
                      placeholder="Minimum bet per round/number 3 B"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Maximum bet per round/number 3 B</label>
                    <b-form-input
                      type="number"
                      v-model="MaxB3"
                      disabled
                      placeholder="Maximum bet per round/number 3 B"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Maximum 3 B</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitB3"
                      placeholder="Maximum 3 B"
                      disabled
                    />
                    <b-button
                     v-for="(i,index) in StepRateB3" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                      <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayB3"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateB3" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
            <!-- 3บน -->
            <b-col cols="12" >
              <b-form-group
                label="3 Up"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                      <label for="">Maximum bet per number 3 Up</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitU3"
                      disabled
                      placeholder="Maximum bet per number 3 Up"
                    />
                  </b-col>
                  <b-col cols="5">
                    <label for="">Pay 3 Up</label>
                    <b-form-input
                      type="number"
                      v-model="PayU3"
                      placeholder="Pay 3 Up"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                      <label for="">Maximum pay 3 Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU3"
                      disabled
                      placeholder="Maximum pay 3 Up"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number 3 Up</label>
                    <b-form-input
                      type="number"
                      v-model="MinU3"
                      disabled
                      placeholder="Minimum bet per round/number 3 Up"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Maximum bet per round/number 3 Up</label>
                    <b-form-input
                      type="number"
                      v-model="MaxU3"
                      disabled
                      placeholder="Maximum bet per round/number 3 Up"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Maximum 3 Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU3"
                      placeholder="Maximum 3 Up"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateU3" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index +1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayU3"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateU3" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- 3โต๊ด -->
            <b-col cols="12" >
              <b-form-group
                label="3 TOT"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                      <label for="">Maximum bet per number 3 TOT</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitT3"
                      disabled
                      placeholder="Maximum bet per number 3 TOT"
                    />
                  </b-col>
                  <b-col cols="5">
                      <label for="">Pay 3 TO</label>
                    <b-form-input
                      type="number"
                      v-model="PayT3"
                      placeholder="Pay 3 TOT"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                      <label for="">Maximum pay 3 TOT</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayT3"
                      disabled
                      placeholder="Maximum pay 3 TOT"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number 3 TOT</label>
                    <b-form-input
                      type="number"
                      v-model="MinT3"
                      disabled
                      placeholder="Minimum bet per round/number 3 TOT"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Maximum bet per round/number 3 TOT</label>
                    <b-form-input
                      type="number"
                      v-model="MaxT3"
                      disabled
                      placeholder="Maximum bet per round/number 3 TOT"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout"> 
                      <label for="">Maximum 3 TOT</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayT3"
                      placeholder="Maximum 3 TOT"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateT3" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                      <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayT3"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateT3" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- 2บน -->
            <b-col cols="12" >
              <b-form-group
                label="2 Up"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                      <label for="">Maximum bet per number 2 Up</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitU2"
                      disabled
                      placeholder="Maximum bet per number 2 Up"
                    />
                  </b-col>
                  <b-col cols="5">
                    <label for="">Pay 2 Up</label>
                    <b-form-input
                      type="number"
                      v-model="PayU2"
                      placeholder="Pay 2 Up"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                    <label for="">Maximum pay 2 Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU2"
                      disabled
                      placeholder="Maximum pay 2 Up"
                    />
                  </b-col>
                  <b-col cols="6">
                      <label for="">Minimum bet per round/number 2 Up</label>
                    <b-form-input
                      type="number"
                      v-model="MinU2"
                      disabled
                      placeholder="Minimum bet per round/number 2 Up"
                    />
                  </b-col>
                  <b-col cols="6">
                      <label for="">Maximum bet per round/number 2 Up</label>
                    <b-form-input
                      type="number"
                      v-model="MaxU2"
                      disabled
                      placeholder="Maximum bet per round/number 2 Up"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Maximum 2 Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU2"
                      placeholder="Maximum 2 Up"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateU2" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index +1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                      <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayU2"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateU2" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- 2ล่าง -->
            <b-col cols="12" >
              <b-form-group
                label="2 Down"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                      <label for="">Maximum bet per number 2 Down</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitD2"
                      disabled
                      placeholder="Maximum bet per number 2 Down"
                    />
                  </b-col>
                  <b-col cols="5">
                    <label for="">Pay 2 Down</label>
                    <b-form-input
                      type="number"
                      v-model="PayD2"
                      placeholder="Pay 2 Down"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                      <label for="">Maximum pay 2 Down</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayD2"
                      disabled
                      placeholder="Maximum pay 2 Down"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number 2 Down</label>
                    <b-form-input
                      type="number"
                      v-model="MinD2"
                      disabled
                      placeholder="Minimum bet per round/number 2 Down"
                    />
                  </b-col>
                  <b-col cols="6">
                      <label for="">Maximum bet per round/number 2 Down</label>
                    <b-form-input
                      type="number"
                      v-model="MaxD2"
                      disabled
                      placeholder="Maximum bet per round/number 2 Down"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Maximum 2 Down</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayD2"
                      placeholder="Maximum 2 Down"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateD2" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6"  v-if="tieredPayout">
                      <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayD2"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateD2" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- วิ่งบน -->
            <b-col cols="12" >
              <b-form-group
                label="Run Up"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4">
                    <label for="">Maximum bet per number Run Up</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitU1"
                      disabled
                      placeholder="Maximum bet per number Run Up"
                    />
                  </b-col>
                  <b-col cols="5">
                      <label for="">Pay Run Up</label>
                    <b-form-input
                      type="number"
                      v-model="PayU1"
                      placeholder="Pay Run Up"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                      <label for="">Maximum pay Run Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU1"
                      disabled
                      placeholder="Maximum pay Run Up"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number Run Up</label>
                    <b-form-input
                      type="number"
                      v-model="MinU1"
                      disabled
                      placeholder="Minimum bet per round/number Run Up"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Maximum bet per round/number Run Up</label>
                    <b-form-input
                      type="number"
                      v-model="MaxU1"
                      disabled
                      placeholder="Maximum bet per round/number Run Up"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                    <label for="">Maximum Run Up</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayU1"
                      placeholder="Maximum Run Up"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateU1" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                      <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayU1"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateU1" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- วิ่งล่าง -->
            <b-col cols="12" >
              <b-form-group
                label="Run Down"
                label-for="customDelimiter"
              >
                <b-row>
                  <b-col cols="4" >
                    <label for="">Maximum bet per number Run Down</label>
                    <b-form-input
                      type="number"
                      v-model="BetLimitD1"
                      disabled
                      placeholder="Maximum bet per number Run Down"
                    />
                  </b-col>
                  <b-col cols="5">
                      <label for="">Pay Run Down</label>
                    <b-form-input
                      type="number"
                      v-model="PayD1"
                      placeholder="Pay Run Down"
                      disabled
                    />
                  </b-col>
                  <b-col cols="3">
                    <label for="">Maximum pay Run Down</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayD1"
                      disabled
                      placeholder="Maximum pay Run Down"
                    />
                  </b-col>
                  <b-col cols="6">
                    <label for="">Minimum bet per round/number Run Down</label>
                    <b-form-input
                      type="number"
                      v-model="MinD1"
                      disabled
                      placeholder="Minimum bet per round/number Run Down"
                    />
                  </b-col>
                  <b-col cols="6">
                      <label for="">Maximum bet per round/number Run Down</label>
                    <b-form-input
                      type="number"
                      v-model="MaxD1"
                      disabled
                      placeholder="Maximum bet per round/number Run Down"
                    />
                    
                  </b-col>

                  <b-col cols="6" v-if="tieredPayout">
                      <label for="">Maximum Run Down</label>
                    <b-form-input
                      type="number"
                      v-model="maxPayD1"
                      placeholder="Maximum Run Down"
                      disabled
                    />
                    <b-button
                     v-for="(i, index) in StepRateD1" :key="index"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      Class {{ index+1 }}
                    </b-button>
                  </b-col>
                  <b-col cols="6" v-if="tieredPayout">
                      <label for="">Class</label>
                    <b-form-input
                      v-model="maxPayD1"
                      placeholder="Class"
                      disabled
                    />
                    <b-button
                     v-for="i in StepRateD1" :key="i"
                      variant="gradient-secondary"
                      block
                      disabled
                    >
                      {{ i }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
// import moment from 'moment-timezone'
import {
  BModal,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    vSelect,
    BModal,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      acc_name: "",
      acc_no: "",
      depositdata: [],
      bankData: "",
      bankname: "",
      bankcode: "",
      optionsMember: "",
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "index", label: "no." },
        { key: "bankname", label: "ธนาคาร" },
        { key: "acc_no", label: "เลขบัญชี" },
        { key: "acc_name", label: "ชื่อ-สกุล" },
        // { key: 'phone', label: 'เบอร์มือถือ' },
        { key: "actions", label: "Action" },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      StoreForm: {
        ID: null,
        card_id: "",
        date_of_brith: "",
        phone: "",

        accname: "",
        accno: "",
        pin: "",
        otp: "",
        device_id: null,
        Auth: null,
        ref: null,
        tokenUUID: null,
        bank: "scb",
      },
      step: 1,
      show: false,
      banklist: [],
      rateLimit: null,
      rateitem: [],

      BetLimitU4: 0,
      PayU4: 0,
      MinU4: 0,
      MaxU4: 0,
      maxPayU4: 0,
      StepRateU4: [],
      tieredPayout: false,


      BetLimitU3: 0,
      PayU3: 0,
      MinU3: 0,
      MaxU3: 0,
      maxPayU3: 0,
      StepRateU3: [],

      BetLimitF3: 0,
      PayF3: 0,
      MinF3: 0,
      MaxF3: 0,
      maxPayF3: 0,
      StepRateF3: [],


      BetLimitB3: 0,
      PayB3: 0,
      MinB3: 0,
      MaxB3: 0,
      maxPayB3: 0,
      StepRateB3: [],


      BetLimitT3: 0,
      PayT3: 0,
      MinT3: 0,
      MaxT3: 0,
      maxPayT3: 0,
      StepRateT3: [],


      BetLimitU2: 0,
      PayU2: 0,
      MinU2: 0,
      MaxU2: 0,
      maxPayU2: 0,
      StepRateU2: [],


      BetLimitD2: 0,
      PayD2: 0,
      MinD2: 0,
      MaxD2: 0,
      maxPayD2: 0,
      StepRateD2: [],


      BetLimitU1: 0,
      PayU1: 0,
      MinU1: 0,
      MaxU1: 0,
      maxPayU1: 0,
      StepRateU1: [],


      BetLimitD1: 0,
      PayD1: 0,
      MinD1: 0,
      MaxD1: 0,
      maxPayD1: 0,
      StepRateD1: [],

    };
  },
  mounted() {
    this.AgentRate();
    this.getData();
    // this.Get_bank()
  },
  methods: {
    AgentRate() {
      this.show = true;
      this.$http
        .get("/get/AgentRateLimit")
        .then((response) => {
          this.rateitem = response.data.rateLimit;
          this.show = false
          // this.onFiltered(response.data)
        })
        .catch((error) => {
          console.log(error)
          this.show = false
        });
    },
    Submit() {
      this.show = true;
      const obj = {
        bankcode: this.bankcode,
        acc_name: this.acc_name,
        acc_no: this.acc_no,
      };
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post("/bankcheck/add", obj)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // console.log(response.data)
          this.Success("เพิ่มข้อมูลบัญชีสำเร็จ");
          this.getData();
          this.acc_name = null;
          this.acc_no = null;
          this.bankcode = null;
          this.show = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.SwalError("เพิ่มข้อมูลบัญชีไม่สำเร็จ");
          this.show = false;
        });
    },
    SelectedRate() {
        if (this.rateitem) {
            const Data = this.rateLimit.find(ele => ele.code == this.rateitem)
            this.tieredPayout = Data.tieredPayout
            // this.BetLimitU4 = Data.U4MaxLimit
            // this.PayU4 = Data.U4Pay
            // this.MinU4 = Data.U4MinBet
            // this.MaxU4 = Data.U4MaxBet
            // this.maxPayU4 = Data.U4Pay * Data.U4MaxLimit
            // this.StepRateU4 = Data.U4StepPay

            // this.BetLimitF3 = Data.F3MaxLimit
            // this.PayF3 = Data.F3Pay
            // this.MinF3 = Data.F3MinBet
            // this.MaxF3 = Data.F3MaxBet
            // this.maxPayF3 = Data.F3Pay *Data.F3MaxLimit
            // this.StepRateF3 = Data.F3StepPay

            this.BetLimitU3 = Data.U3MaxLimit
            this.PayU3 = Data.U3Pay
            this.MinU3 = Data.U3MinBet
            this.MaxU3 = Data.U3MaxBet
            this.maxPayU3 = Data.U3Pay *Data.U3MaxLimit
            this.StepRateU3 = Data.U3StepPay

            // this.BetLimitB3 = Data.B3MaxLimit
            // this.PayB3 = Data.B3Pay
            // this.MinB3 = Data.B3MinBet
            // this.MaxB3 = Data.B3MaxBet
            // this.maxPayB3 = Data.B3Pay *Data.B3MaxLimit
            // this.StepRateB3 = Data.B3StepPay

            this.BetLimitT3 = Data.T3MaxLimit
            this.PayT3 = Data.T3Pay
            this.MinT3 = Data.T3MinBet
            this.MaxT3 = Data.T3MaxBet
            this.maxPayT3 = Data.T3Pay *Data.T3MaxLimit
            this.StepRateT3 = Data.T3StepPay

            this.BetLimitU2 = Data.U2MaxLimit
            this.PayU2 = Data.U2Pay
            this.MinU2 = Data.U2MinBet
            this.MaxU2 = Data.U2MaxBet
            this.maxPayU2 = Data.U2Pay *Data.U2MaxLimit
            this.StepRateU2 = Data.U2StepPay

            this.BetLimitD2 = Data.D2MaxLimit
            this.PayD2 = Data.D2Pay
            this.MinD2 = Data.D2MinBet
            this.MaxD2 = Data.D2MaxBet
            this.maxPayD2 = Data.D2Pay *Data.D2MaxLimit
            this.StepRateD2 = Data.D2StepPay

            this.BetLimitU1 = Data.U1MaxLimit
            this.PayU1 = Data.U1Pay
            this.MinU1 = Data.U1MinBet
            this.MaxU1 = Data.U1MaxBet
            this.maxPayU1 = Data.U1Pay *Data.U1MaxLimit
            this.StepRateU1 = Data.U1StepPay

            this.BetLimitD1 = Data.D1MaxLimit
            this.PayD1 = Data.D1Pay
            this.MinD1 = Data.D1MinBet
            this.MaxD1 = Data.D1MaxBet
            this.maxPayD1 = Data.D1Pay *Data.D1MaxLimit
            this.StepRateD1 = Data.D1StepPay
          }
    },
    SaveRate() {
      const obj = {
        code: this.rateitem,
      }
      this.$http.post('save/AgentRateLimit', obj).then(response => {
        if (response.data) {
          this.AgentRate()
          this.Success()
        } else {
          this.SwalError()
        }
       })
    },
    getData() {
        this.show = true;
      this.$http
        .get("/get/ratelimit")
        .then((response) => {
          this.rateLimit = response.data.data;
          if (this.rateitem) {
            const Data = this.rateLimit.find(ele => ele.code == this.rateitem)
            this.tieredPayout = Data.tieredPayout
            // this.BetLimitU4 = Data.U4MaxLimit
            // this.PayU4 = Data.U4Pay
            // this.MinU4 = Data.U4MinBet
            // this.MaxU4 = Data.U4MaxBet
            // this.maxPayU4 = Data.U4Pay * Data.U4MaxLimit
            // this.StepRateU4 = Data.U4StepPay

            // this.BetLimitF3 = Data.F3MaxLimit
            // this.PayF3 = Data.F3Pay
            // this.MinF3 = Data.F3MinBet
            // this.MaxF3 = Data.F3MaxBet
            // this.maxPayF3 = Data.F3Pay *Data.F3MaxLimit
            // this.StepRateF3 = Data.F3StepPay

            this.BetLimitU3 = Data.U3MaxLimit
            this.PayU3 = Data.U3Pay
            this.MinU3 = Data.U3MinBet
            this.MaxU3 = Data.U3MaxBet
            this.maxPayU3 = Data.U3Pay *Data.U3MaxLimit
            this.StepRateU3 = Data.U3StepPay

            // this.BetLimitB3 = Data.B3MaxLimit
            // this.PayB3 = Data.B3Pay
            // this.MinB3 = Data.B3MinBet
            // this.MaxB3 = Data.B3MaxBet
            // this.maxPayB3 = Data.B3Pay *Data.B3MaxLimit
            // this.StepRateB3 = Data.B3StepPay

            this.BetLimitT3 = Data.T3MaxLimit
            this.PayT3 = Data.T3Pay
            this.MinT3 = Data.T3MinBet
            this.MaxT3 = Data.T3MaxBet
            this.maxPayT3 = Data.T3Pay *Data.T3MaxLimit
            this.StepRateT3 = Data.T3StepPay

            this.BetLimitU2 = Data.U2MaxLimit
            this.PayU2 = Data.U2Pay
            this.MinU2 = Data.U2MinBet
            this.MaxU2 = Data.U2MaxBet
            this.maxPayU2 = Data.U2Pay *Data.U2MaxLimit
            this.StepRateU2 = Data.U2StepPay

            this.BetLimitD2 = Data.D2MaxLimit
            this.PayD2 = Data.D2Pay
            this.MinD2 = Data.D2MinBet
            this.MaxD2 = Data.D2MaxBet
            this.maxPayD2 = Data.D2Pay *Data.D2MaxLimit
            this.StepRateD2 = Data.D2StepPay

            this.BetLimitU1 = Data.U1MaxLimit
            this.PayU1 = Data.U1Pay
            this.MinU1 = Data.U1MinBet
            this.MaxU1 = Data.U1MaxBet
            this.maxPayU1 = Data.U1Pay *Data.U1MaxLimit
            this.StepRateU1 = Data.U1StepPay

            this.BetLimitD1 = Data.D1MaxLimit
            this.PayD1 = Data.D1Pay
            this.MinD1 = Data.D1MinBet
            this.MaxD1 = Data.D1MaxBet
            this.maxPayD1 = Data.D1Pay *Data.D1MaxLimit
            this.StepRateD1 = Data.D1StepPay
              this.show = false;
          }
          // this.onFiltered(response.data)
        })
        .catch((error) => {
          console.log(error)
          this.show = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addData() {
      this.$refs["my-modal"].show();
    },
    GetDeviceIdScb() {
      this.show = true;
      if (this.step === 1) {
        const obj = {
          cardId: this.StoreForm.card_id,
          dateOfBirth: this.StoreForm.date_of_brith,
          MobilePhoneNo: this.StoreForm.phone,
        };
        this.$http
          .post("/setting/withdrawscb/deviceid", obj)
          .then((response) => {
            // console.log(response.data)
            if (response.data.Auth) {
              this.show = false;
              this.step = 2;
              this.StoreForm.ID = response.data.ID;
              this.StoreForm.device_id = response.data.deviceId;
              this.StoreForm.Auth = response.data.Auth;
              this.StoreForm.ref = response.data.ref;
              this.StoreForm.tokenUUID = response.data.tokenUUID;
              this.addData();
            }
          })
          .catch((error) => {
            this.show = false;
            this.SwalError(error.response.data.message.statusdesc);
            this.addData();
          });
      } else {
        const obj = {
          ID: this.StoreForm.ID,
          acc_name_scb: this.StoreForm.accname,
          acc_no: this.StoreForm.accno,
          bank: this.StoreForm.bank,
          phone: this.StoreForm.phone,
          pin: this.StoreForm.pin,
          otp: this.StoreForm.otp,
          deviceId: this.StoreForm.device_id,
          Auth: this.StoreForm.Auth,
          ref: this.StoreForm.ref,
          tokenUUID: this.StoreForm.tokenUUID,
        };
        this.$http
          .post("/setting/withdrawscb/confirmotp", obj)
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            // console.log(response.data)
            this.Success("เพิ่มข้อมูลโยกเงินสำเร็จ");
            this.acc_name_scb = "";
            this.deviceId = "";
            this.Auth = "";
            this.ref = "";
            this.tokenUUID = "";
            this.MobilePhoneNo = "";
            this.pin = "";
            this.otp = "";
            this.cardId = "";
            this.dateOfBirth = "";
            this.acc_no = "";
            this.show = false;
            this.step = 1;
          })
          .catch((error) => {
            this.step = 1;
            this.SwalError(error.response.data.message);
            this.showModal();
            this.show = false;
          });
      }
    },
    deleteData(id) {
      this.$swal({
        title: "ลบบัญชี?",
        text: "กรุณายืนยันการลบบัญชี",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const params = {
            id,
          };
          this.$http
            .get("/bankcheck/delete", { params })
            .then((response) => {
              this.getData();
              if (response.data) {
                this.$swal({
                  icon: "success",
                  title: '<h3 style="color: #141414">ลบข้อมูลสำเร็จ</h3>',
                  text: "",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal(
                "เกิดข้อผิดพลาด",
                error.response.data.message,
                "error"
              );
            });
        } else {
          this.$swal("ยกเลิก", "", "error");
        }
      });
    },
    editData(data) {
      this.bankData = data;
      this.bankname = data.name;
      this.bankcode = data.bankcode;
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
