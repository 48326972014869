<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-gradient-dark"
              @click="getUsers()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0 d-flex"
          >
            <router-link :to="{ name: 'member-add' }">
              <!-- <b-button
                v-if="UserData.agent_id === 1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="gradient-primary"
                block
              >
                ย้ายลูกค้าเข้าเว็บ
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="gradient-primary"
                block
              >
                สมัครให้ลูกค้า
              </b-button>
            </router-link>
          </b-form-group>
          <div>
            <!-- <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />

                  <b-form-input
                    v-model="search_val"
                    :disabled="!search_type"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    block
                    @click="getUsers()"
                  >
                    <feather-icon icon="SearchIcon" />
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row> -->
          </div>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <template #cell(ufa_password)="data">
          <span v-if="user && (user.userID == 1 || user.userID == 370) ">{{ data.item.ufa_password }}</span>
        </template>
        <template #cell(username)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.username }}
          </span>
          <span
            v-else-if="data.item.vip"
            style="color: blue"
          >
            {{ data.item.username }}
          </span>
          <span v-else>
            {{ data.item.username }}
          </span>
        </template>
        <!-- <template #cell(tel)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.tel }}
          </span>
          <span v-else>
            {{ data.item.tel }}
          </span>
        </template>
        <template #cell(name)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.name }}
          </span>
          <span v-else>
            {{ data.item.name }}
          </span>
        </template>
        <template #cell(surname)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.surname }}
          </span>
          <span v-else>
            {{ data.item.surname }}
          </span>
        </template> -->
        <!-- <template #cell(status)="">
          <div class="text-nowrap">
            <b-form-checkbox
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span
                class="switch-icon-left"
                style="font-size: 1px"
              >
                <i class="fas fa-user" />
              </span>
              <span class="switch-icon-right">
                <i class="fas fa-user-slash" />
              </span>
            </b-form-checkbox>
          </div>
        </template> -->
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              v-b-modal.modal-edit
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editmember(data.item)"
            />
            <b-tooltip
              title="Promotion"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
            <b-button
              v-b-tooltip.hover.v-info
              title="รายละเอียด"
              variant="gradient-info"
              size="sm"
              class="mr-1"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
            >
              <i class="fas fa-address-card" />
            </b-button>
            <i
              v-b-tooltip.hover
              class="fas fa-trash text-danger"
              title="ลบยูสเซอร์"
              style="cursor: pointer; font-size: 1.5rem;"
              @click="deleteuser(data.item)"
            />

            <!-- <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="FileTextIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
            /> -->
            <!-- <b-tooltip
              title="History Deposit & Withdraw"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            /> -->
            <!-- <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="HardDriveIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'History-Play', params: { id: data.item.username }})"
            />
            <b-tooltip
              title="History Play"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            /> -->
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getUsers()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getUsers()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-edit"
        no-close-on-backdrop
        title="Edit Member"
        ok-only
        ok-title="Save"
        @ok="update_member"
      >
        <b-form-group
          label="Name"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Surname"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_surname"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Password"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_password"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="ธนาคาร"
          label-for="vi-first-name"
        >
          <v-select
            v-model="edit_bank"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="banklist"
            :reduce="name => name.bankcode"
          />
        </b-form-group>
        <b-form-group
          label="เลขบัญชี"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_acc_no"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-1"
        title="Add Class"
        ok-only
        ok-title="Save"
      >
        <b-form-group
          label="Class"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckSquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-promo"
        title="Promotion"
        no-close-on-backdrop
        ok-title="save"
        @ok="Submit_promotion"
      >
        <b-form-group>
          <v-select
            v-model="pro_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="prolist"
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  // BRow, BCol,
  BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody, VBToggle, BOverlay, BIconController, VBTooltip,
  // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'เบอร์โทร' },
        { value: 3, text: 'ชื่อลูกค้า' },
        { value: 4, text: 'เลขบัญชี' },
        // { value: 5, text: 'เลขกระเป๋าคริปโต' },
      ],
      search_type: null,
      search_val: null,
      show: true,
      prolist: [],
      pro_id: null,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'ufa_password', label: 'รหัสผ่านยูฟ่า' },
        { key: 'tel', label: 'เบอร์โทร' },
        { key: 'name', label: 'ชื่อ' },
        { key: 'surname', label: 'นามสกุล' },

        // { key: 'updated_at', label: 'balance' },
        // { key: 'email', label: 'email' },
        { key: 'bankname', label: 'ธนาคาร' },
        { key: 'acc_no', label: 'เลขบัญชี' },
        // { key: 'crypto_accno', label: 'เลขกระเป๋าคริปโต' },
        { key: 'invited_by_name', label: 'ผู้แนะนำ' },
        { key: 'created_at', label: 'สมัครเมื่อ', formatter: value => (value ? moment(value).tz('Asia/Bangkok').add('hours', 7).format('DD/MM/YYYY HH:mm:ss') : '') },
        // { key: 'pro_name', label: 'promotion' },
        { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      user: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUsers()
  //     // this.Get_promotionlist()
  //   }, 30000)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getUsers()
    this.Get_promotionlist()
    this.Get_bank()
    this.get_user()
  },
  methods: {
    deleteuser(data) {
      this.$swal({
        title: 'ยืนยันที่จะลบยูสเซอร์ ?',
        text: `ยูส : ${data.username} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/users/delete/${data.id}`)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.Success('ลบยูสเซอร์สำเร็จ')
              this.getUsers()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    Export() {
      window.open(
        '/export',
        // '/export',
      )
    },
    addpro(id) {
      this.update_id = id
      // console.log(id)
    },
    editmember(data) {
      this.edit_id = data.id
      this.edit_name = data.name
      this.edit_surname = data.surname
      this.edit_bank = data.bank
      this.edit_acc_no = data.acc_no
      // console.log(data)
    },
    get_user() {
      const u = localStorage.getItem('userData')

      this.user = JSON.parse(u)
    },
    update_member() {
      const params = {
        name: this.edit_name,
        password: this.edit_password,
        surname: this.edit_surname,
        bank: this.edit_bank,
        acc_no: this.edit_acc_no,
      }
      // console.log(params)
      this.$http
        .post(`/users/update_namepass/${this.edit_id}`, params)
        .then(response => {
          console.log(response)
          this.show = false
          this.getUsers()
          this.edit_name = ''
          this.edit_password = ''
          this.edit_surname = ''
          this.edit_bank = ''
          this.edit_acc_no = ''
        })
        .catch(error => console.log(error))
    },
    Get_bank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    Submit_promotion() {
      const params = {
        main_promotion: this.pro_id,
      }
      this.$http
        .post(`/users/update/${this.update_id}`, { params })
        .then(() => {
          this.getUsers()
          this.Success('อัพเดทโปรโมชั่น สำเร็จ')
          this.update_id = null
          this.pro_id = null
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Get_promotionlist() {
      this.$http
        .get('/promotion/list')
        .then(response => {
          this.prolist = response.data
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async getUsers() {
      this.show = true
      let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      await this.$http
        .get('/users', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          this.depositdata.forEach(items => {
            this.getBalance(items.username, index)
            index += 1
          })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getBalance(username, index) {
      const params = {
        username,
      }
      this.$http
        .get('/balance/getbalance', { params })
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.depositdata[index].updated_at = response.data.balance
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
