import Vue from 'vue'

// axios
import axios from 'axios'

let site = window.location.origin
// let site = 'https://admin.ddufa-789bak.com'
if (site === 'http://localhost:8080' && localStorage.getItem('devsite')) {
  site = localStorage.getItem('devsite')
}
const obj = {
  site_admin: site,
  // site_admin: 'https://admin.ufa-bak-7777.com',
}
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // baseURL: endpoint,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.request.use(
  async config => {
    let endpoint
    const { origin } = window

    if (origin.includes('ddufa')) {
      endpoint = 'https://api.ddufa-789bak.com/api'
      obj.site_admin = 'https://admin.ddufa-789bak.com'
    } else if (origin.includes('ufabrother')) {
      endpoint = 'https://api.ufabrother-bkend.com/api'
      obj.site_admin = 'https://admin.ufabrother-bkend.com'
    } else if (origin.includes('ufa9')) {
      endpoint = 'https://api.bo-ufa9.com/api'
      obj.site_admin = 'https://admin.bo-ufa9.com'
    } else {
      endpoint = 'http://localhost:4040/api'
    }

    await axios.post(`${endpoint}/agent/showid`, obj)
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        localStorage.setItem('endpoint', response.data.endpoint)
        config.baseURL = response.data.endpoint
        // config.baseURL = 'http://127.0.0.1:4040/api/'
      })
      .catch(error => console.log(error))
    // console.log(config.headers.Authorization)

    return config
  },
  error => Promise.reject(error),
)
Vue.prototype.$http = axiosIns

export default axiosIns
