<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="gradient-primary"
          @click="submit"
        >
          <i
            class="fad fa-cloud-download-alt"
            style="color: #fff"
          />
          บันทึกข้อมูล
        </b-button>
      </div>
      <b-row class="match-height mt-1">
        <b-col md="7">
          <b-card>
            <h3>
              <i class="fad fa-university" /> จัดการเลขบัญชีธนาคารลูกค้าโอนเข้า
            </h3>
            <b-tabs pills>
              <b-tab
                title="บัญชีฝากที่ 1"
                active
              >
                <b-form-group>
                  <v-select
                    v-model="agent.dip_bank_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.code"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="agent.dip_bank_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.dip_bank_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <div class="d-flex justify-content-between align-items-center">
                  <b-form-group
                    label="เปิด / ปิด บัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-checkbox
                      v-model="agent.checkbank1"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="px-4 btn-icon"
                    @click="showModal(bank = 1)"
                  >
                    เพิ่มข้อมูลโยกเงิน
                  </b-button> -->
                </div>
                <hr>

                <!-- <div class="card-credit">
                  <h4>
                    โยกเงินอัตโนมัติ SCB 1
                  </h4>
                  <b-form-group
                    label="เปิด / ปิด โยกอัตโนมัติ"
                    label-for="customDelimiter"
                  >
                    <b-form-checkbox
                      v-model="AutoData.move_dep1_status"
                      class="custom-control-success"
                      name="check-button"
                      :value="1"
                      :unchecked-value="0"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    label="จำนวนเงินที่ถอน"
                    label-for="vi-first-name"
                  >
                    <b-form-input
                      id="vi-first-name"
                      v-model="AutoData.move_dep1_amount"
                      placeholder="0"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="web">ถอนไปยังธนาคาร</label>
                    <v-select
                      v-model="AutoData.move_dep1_bank"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.bankcode"
                      class="bg-white rounded"
                      placeholder="เลือกธนาคาร"
                    />
                  </b-form-group>
                  <b-form-group
                    label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                    label-for="vi-first-acc"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="AutoData.move_dep1_acc"
                        placeholder="ระบุเลขที่บัญชี"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="checkAcc(1)"
                        >
                          ค้นหา
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="confirm(1)"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </div> -->
              </b-tab>

              <b-tab title="บัญชีฝากที่ 2">
                <div>
                  <b-form-group>
                    <v-select
                      v-model="agent.dip_bank3_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.code"
                    />
                  </b-form-group>
                  <b-form-group
                    label="เลขที่บัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label1"
                      v-model="agent.dip_bank3_accno"
                      placeholder="เลขที่บัญชี"
                    />
                  </b-form-group>

                  <b-form-group
                    label="ชื่อบัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank3_accname"
                      placeholder="ชื่อบัญชี"
                    />
                  </b-form-group>

                  <div class="d-flex justify-content-between align-items-center">
                    <b-form-group
                      label="เปิด / ปิด บัญชี"
                      label-for="customDelimiter"
                    >
                      <b-form-checkbox
                        v-model="agent.checkbank3"
                        class="custom-control-success"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>

                    <!-- <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="px-4 btn-icon"
                      @click="showModal(bank = 2)"
                    >
                      เพิ่มข้อมูลโยกเงิน
                    </b-button> -->
                  </div>
                </div>

                <hr>

                <!-- <div class="card-credit">
                  <h4>
                    โยกเงินอัตโนมัติ SCB 2
                  </h4>
                  <b-form-group
                    label="เปิด / ปิด โยกอัตโนมัติ"
                    label-for="customDelimiter"
                  >
                    <b-form-checkbox
                      v-model="AutoData.move_dep3_status"
                      class="custom-control-success"
                      name="check-button"
                      :value="1"
                      :unchecked-value="0"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    label="จำนวนเงินที่ถอน"
                    label-for="vi-first-name"
                  >
                    <b-form-input
                      id="vi-first-name"
                      v-model="AutoData.move_dep3_amount"
                      placeholder="0"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="web">ถอนไปยังธนาคาร</label>
                    <v-select
                      v-model="AutoData.move_dep3_bank"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.bankcode"
                      class="bg-white rounded"
                      placeholder="เลือกธนาคาร"
                    />
                  </b-form-group>
                  <b-form-group
                    label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                    label-for="vi-first-acc"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="AutoData.move_dep3_acc"
                        placeholder="ระบุเลขที่บัญชี"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="checkAcc(3)"
                        >
                          ค้นหา
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="confirm(3)"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </div> -->
              </b-tab>

              <!-- <b-tab title="KBANK (กสิกรไทย)">
                <div>
                  <b-form-group>
                    <v-select
                      v-model="agent.dip_bank2_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.bankcode"
                    />
                  </b-form-group>
                  <b-form-group
                    label="เลขที่บัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label1"
                      v-model="agent.dip_bank2_accno"
                      placeholder="เลขที่บัญชี"
                    />
                  </b-form-group>

                  <b-form-group
                    label="ชื่อบัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank2_accname"
                      placeholder="ชื่อบัญชี"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Kbiz username"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank2_username"
                      placeholder="username"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Kbiz password"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank2_password"
                      placeholder="password"
                    />
                  </b-form-group>
                  <div class="d-flex justify-content-between align-items-center">
                    <b-form-group
                      label="เปิด / ปิด บัญชี"
                      label-for="customDelimiter"
                    >
                      <b-form-checkbox
                        v-model="agent.checkbank2"
                        class="custom-control-success"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="px-4 btn-icon"
                      @click="showModalkbank(service_type_kbank = 'deposit')"
                    >
                      เชื่อมออโต้ฝาก kbank
                    </b-button>
                  </div>
                </div>

                <hr>

                <div class="card-credit">
                  <h4>
                    โยกเงินอัตโนมัติ KBANK
                  </h4>
                  <b-form-group
                    label="เปิด / ปิด โยกอัตโนมัติ"
                    label-for="customDelimiter"
                  >
                    <b-form-checkbox
                      v-model="AutoData.move_dep2_status"
                      class="custom-control-success"
                      name="check-button"
                      :value="1"
                      :unchecked-value="0"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    label="จำนวนเงินที่ถอน"
                    label-for="vi-first-name"
                  >
                    <b-form-input
                      id="vi-first-name"
                      v-model="AutoData.move_dep2_amount"
                      placeholder="0"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="web">ถอนไปยังธนาคาร</label>
                    <v-select
                      v-model="AutoData.move_dep2_bank"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.bankcode"
                      class="bg-white rounded"
                      placeholder="เลือกธนาคาร"
                    />
                  </b-form-group>
                  <b-form-group
                    label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                    label-for="vi-first-acc"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="AutoData.move_dep2_acc"
                        placeholder="ระบุเลขที่บัญชี"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="checkAcc(2)"
                        >
                          ค้นหา
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="confirm(2)"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </div>
              </b-tab> -->

              <!-- <b-tab title="KBANK (กสิกรไทย) 2">
                <div>
                  <b-form-group>
                    <v-select
                      v-model="agent.dip_bank4_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.code"
                    />
                  </b-form-group>
                  <b-form-group
                    label="เลขที่บัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label1"
                      v-model="agent.dip_bank4_accno"
                      placeholder="เลขที่บัญชี"
                    />
                  </b-form-group>

                  <b-form-group
                    label="ชื่อบัญชี"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank4_accname"
                      placeholder="ชื่อบัญชี"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Kbiz username"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank4_username"
                      placeholder="username"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Kbiz password"
                    label-for="customDelimiter"
                  >
                    <b-form-input
                      id="floating-label2"
                      v-model="agent.dip_bank4_password"
                      placeholder="password"
                    />
                  </b-form-group>
                  <div class="d-flex justify-content-between align-items-center">
                    <b-form-group
                      label="เปิด / ปิด บัญชี"
                      label-for="customDelimiter"
                    >
                      <b-form-checkbox
                        v-model="agent.checkbank4"
                        class="custom-control-success"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>

                <hr>

                <div class="card-credit">
                  <h4>
                    โยกเงินอัตโนมัติ KBANK
                  </h4>
                  <b-form-group
                    label="เปิด / ปิด โยกอัตโนมัติ"
                    label-for="customDelimiter"
                  >
                    <b-form-checkbox
                      v-model="AutoData.move_dep2_status"
                      class="custom-control-success"
                      name="check-button"
                      :value="1"
                      :unchecked-value="0"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    label="จำนวนเงินที่ถอน"
                    label-for="vi-first-name"
                  >
                    <b-form-input
                      id="vi-first-name"
                      v-model="AutoData.move_dep2_amount"
                      placeholder="0"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="web">ถอนไปยังธนาคาร</label>
                    <v-select
                      v-model="AutoData.move_dep2_bank"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="banklist"
                      :reduce="name => name.code"
                      class="bg-white rounded"
                      placeholder="เลือกธนาคาร"
                    />
                  </b-form-group>
                  <b-form-group
                    label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                    label-for="vi-first-acc"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="AutoData.move_dep2_acc"
                        placeholder="ระบุเลขที่บัญชี"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="checkAcc(2)"
                        >
                          ค้นหา
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="confirm(2)"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </div>
              </b-tab> -->

              <!-- <b-tab title="CRYPTO (USDT)">
                <p style="color:red">
                  การใช้งาน (สำคัญ)
                </p>
                <p>
                  1.เปิดใช้งานครั้งแรก คลิกปุ่ม สร้างกระเป๋า crypto
                </p>
                <p>
                  2. ช่องUSDT & BNB Deposit Address และ Network ใช้สำหรับโอนเหรียญเข้ามาในกระเป๋า
                </p>
                <p>
                  3. ภายในกระเป๋ามี 2 สกุล คือ 1. USDT คือ สกุลใช้ฝาก/ถอน 2. BNB คือ สกุลใช้จ่ายค่าธรรมเนียมถอน
                </p>
                <p>
                  3.1 สกุลUSDT อัตตราแลกเปลี่ยนเปลี่ยนแปลงได้ตามราคาตลาด (เปลี่ยนไม่มาก) เมื่อลูกค้า ฝาก/ถอน จะถูกเปลี่ยนเป็นสกุลไทยบาท
                </p>
                <p>
                  3.2 สกุลBNB ใช้จ่ายค่าธรรมเนียมการถอน (จำเป็นต้องมีติดกระเป๋า) เมื่อลูกค้าถอนระบบจะจ่ายค่าธรรมเนียมให้ด้วยสกุล BNB แต่จะหักจากกระเป๋า USDT ของลูกค้า เช่น ถอน 1000 บาท อัตตราแลกเปลี่ยน 1usdt = 34.23บาท ระบบจะคำนวน 29.21USDT และค่าธรรมเนียม 0.21USDT ลูกค้าจะได้รับ 29USDT
                </p>
                <p style="color:orange">
                  *** กรณีถอนเงินแต่ไม่มีสกุลBNBในกระเป๋า ระบบจะไม่สามารถทำการถอนได้ ควรมีสกุลBNBมากกว่า 0.1BNB ในกระเป๋า ***
                </p>
                <hr>
                <div v-if="AutoData.crypto_address">
                  <div class="row">
                    <div class="col-md-8">
                      <b-form-group
                        label="USDT & BNB Deposit Address"
                        label-for="Address"
                      >
                        <b-form-input
                          id="Address"
                          v-model="AutoData.crypto_address"
                          placeholder="USDT & BNB Deposit Address"
                          disabled
                        />
                      </b-form-group>

                      <b-form-group
                        label="Network"
                        label-for="Address"
                      >
                        <b-form-input
                          id="Address"
                          v-model="agents.network"
                          placeholder="USDT Deposit Address"
                          disabled
                        />
                      </b-form-group>
                    </div>

                    <div class="col-md-4">
                      <div class="d-flex justify-content-center align-items-center">
                        <qr-code
                          :text="AutoData.crypto_address"
                          size="200"
                          error-level="L"
                          class="my-1"
                        />
                      </div>
                    </div>
                  </div>

                  <b-button
                    v-clipboard:copy="AutoData.crypto_address"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="addAdr()"
                  >
                    คัดลอก Deposit Address
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="GenCryptoWallet()"
                  >
                    สร้างกระเป๋า crypto
                  </b-button>
                </div>

                <hr>

                <div class="card-credit">
                  <h4>
                    สถานะ Crypto
                  </h4>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="g-status success">
                        <i
                          class="fas fa-wallet fa-2x mb-1"
                          style="color:#fff"
                        />
                        <h6
                          class="text-white"
                          style="font-weight: 700"
                        >
                          ปิด-เปิด หน้าฝาก (ลูกค้า)
                        </h6>
                        <b-form-checkbox
                          v-model="agent.check_dep_crypto"
                          class="custom-control-success"
                          name="check-button"
                          :value="1"
                          :unchecked-value="0"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="g-status error">
                        <i
                          class="fas fa-hands-usd fa-2x mb-1"
                          style="color:#fff"
                        />
                        <h6
                          class="text-white"
                          style="font-weight: 700"
                        >
                          ปิด-เปิด หน้าถอน (ลูกค้า)
                        </h6>
                        <b-form-checkbox
                          v-model="agent.check_wit_crypto"
                          class="custom-control-success"
                          name="check-button"
                          :value="1"
                          :unchecked-value="0"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="g-status info">
                        <i
                          class="fas fa-sack-dollar fa-2x mb-1"
                          style="color:#fff"
                        />
                        <h6
                          class="text-white"
                          style="font-weight: 700"
                        >
                          ปิด-เปิด ฝากออโต้ (ระบบ)
                        </h6>
                        <b-form-checkbox
                          v-model="agent.status_dep_crypto"
                          class="custom-control-success"
                          name="check-button"
                          :value="1"
                          :unchecked-value="0"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="g-status warning">
                        <i
                          class="fas fa-sack-dollar fa-2x mb-1"
                          style="color:#fff"
                        />
                        <h6
                          class="text-white"
                          style="font-weight: 700"
                        >
                          ปิด-เปิด ถอนออโต้ (ระบบ)
                        </h6>
                        <b-form-checkbox
                          v-model="agent.status_wit_crypto"
                          class="custom-control-success"
                          name="check-button"
                          :value="1"
                          :unchecked-value="0"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="UpdateStatusCrypto()"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </div>

                <hr>

                <div class="card-credit">
                  <h4>
                    โยกเหรียญ USDT
                  </h4>
                  <b-form-group
                    label="เปิด / ปิด โยกอัตโนมัติ"
                    label-for="customDelimiter"
                  >
                    <b-form-checkbox
                      v-model="AutoData.move_crypto_status"
                      class="custom-control-success"
                      name="check-button"
                      :value="1"
                      :unchecked-value="0"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    label="จำนวนที่ถอน (USDT)"
                    label-for="vi-first-name"
                  >
                    <b-form-input
                      id="vi-first-name"
                      v-model="AutoData.move_crypto_amount"
                      placeholder="0"
                    />
                  </b-form-group>
                  <b-form-group
                    label="ถอนไปยัง Wallet Address"
                    label-for="vi-first-name"
                  >
                    <b-form-input
                      id="vi-first-name"
                      v-model="AutoData.move_crypto_acc"
                      placeholder=""
                    />
                  </b-form-group>

                  <div class="d-flex justify-content-between align-items-center my-1">
                    <div class="d-flex align-items-center">
                      <img
                        src="/bankIcon/usdt.png"
                        alt=""
                        height="40"
                        class="mr-50 rounded"
                      >

                      <div>
                        <h3 class="mb-0">
                          {{ Commas(crypto_usdt) }}
                        </h3>
                        <small>USDT</small>
                      </div>
                    </div>
                  </div>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="px-4 btn-icon"
                    @click="confirm(4)"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </div>
              </b-tab> -->
            </b-tabs>
          </b-card>
        </b-col>
        <b-col md="5">
          <div class="row">
            <div class="col-md-6">
              <div class="g-status success">
                <i
                  class="fas fa-wallet fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด หน้าฝาก
                </h6>
                <b-form-checkbox
                  v-model="agent.status_dip"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="g-status error">
                <i
                  class="fas fa-hands-usd fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด หน้าถอน
                </h6>
                <b-form-checkbox
                  v-model="agent.status_wit"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="g-status info">
                <i
                  class="fas fa-sack-dollar fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด ฝากออโต้ statement
                </h6>
                <b-form-checkbox
                  v-model="agent.status_auto_dip"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="g-status warning">
                <i
                  class="fas fa-ban fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด ถอนออโต้
                </h6>
                <b-form-checkbox
                  v-model="agent.status_auto_wit"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="g-status warning">
                <i
                  class="fas fa-ban fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด ฝากออโต้ SMS
                </h6>
                <b-form-checkbox
                  v-model="agent.dep_sms"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="g-status secondary">
                <i
                  class="fa fa-user-times fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ลบลูกค้าที่ไม่ได้เข้าระบบ 90 วันที่ผ่านมา
                </h6>
                <b-form-checkbox
                  v-model="agent.clearuser"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="g-status secondary">
                <i
                  class="fas fa-power-off fa-2x mb-1"
                  style="color:#fff"
                />

                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด Maintenance
                </h6>
                <b-form-checkbox
                  v-model="agent.maintenance"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="g-status info">
                <i
                  class="fas fa-alarm-exclamation fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700"
                >
                  ปิด-เปิด เสียงแจ้งเตือน
                </h6>
                <b-form-checkbox
                  v-model="agent.alarm"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="g-status info">
                <i
                  class="fas fa-user-plus fa-2x mb-1"
                  style="color:#fff"
                />
                <h6
                  class="text-white"
                  style="font-weight: 700;font-size: 0.9rem;"
                >
                  ปิด-เปิด ตรวจสอบชื่อตรงกับบัญชี(สมัคร)
                </h6>
                <b-form-checkbox
                  v-model="agent.status_checkname"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-gifts" /> จำนวนเทิร์นที่ลูกค้าไม่รับโปร</h3>
            <b-form-group
              label="จำนวนเทิร์น"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.turn_nopro"
                type="number"
                placeholder="จำนวนเทิร์น"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h3>
              <i class="fad fa-donate" /> ยอดถอนเครดิตสูงสุด
              <small>(โดยไม่ต้อง approve)</small>
            </h3>
            <b-form-group
              label="ถอนเครดิตสูงสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.withdraw_approve"
                type="number"
                placeholder="ถอนเครดิตสูงสุด"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h3><i class="fab fa-ethereum" /> ยอดถอนต่อครั้ง</h3>
            <b-form-group
              label="ถอนเครดิตต่ำสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.withdraw_min"
                type="number"
                placeholder="ถอนเครดิตต่ำสุด"
              />
            </b-form-group>
            <b-form-group
              label="ถอนเครดิตสูงสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.withdraw_max"
                type="number"
                placeholder="ถอนเครดิตสูงสุด"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-wallet" /> จัดการ Line</h3>
            <b-form-group
              label="ไลน์ Contact หน้าเว็บ wallet"
              label-for="customDelimiter"
            >
              <b-form-input
                id="linecontact"
                v-model="agent.linecontact"
                placeholder="ไลน์ Contact หน้าเว็บ wallet"
              />
            </b-form-group>
            <b-form-group
              label="ไลน์ Token ฝาก"
              label-for="linetoken_dip"
            >
              <b-form-input
                id="linetoken_dip"
                v-model="agent.linetoken_dip"
                placeholder="ไลน์ Token ฝาก"
              />
            </b-form-group>
            <b-form-group
              label="ไลน์ Token ถอน"
              label-for="linetoken_wit"
            >
              <b-form-input
                id="linetoken_wit"
                v-model="agent.linetoken_wit"
                placeholder="ไลน์ Token ฝาก"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-wallet" /> จัดการข้อมูลส่ง SMS (THSMS)</h3>
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="customDelimiter"
            >
              <b-form-input
                id="linecontact"
                v-model="agent.thssms_user"
                placeholder="ยูสเซอร์เนม"
              />
            </b-form-group>
            <b-form-group
              label="พาสเวิร์ด"
              label-for="linetoken_dip"
            >
              <b-form-input
                id="linetoken_dip"
                v-model="agent.thssms_pass"
                placeholder="พาสเวิร์ด"
              />
            </b-form-group>
            <b-form-group
              label="API-KEY"
              label-for="linetoken_wit"
            >
              <b-form-input
                id="thssms_apikey"
                v-model="agent.thssms_apikey"
                placeholder="API-KEY"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-megaphone" /> ข้อความประกาศ</h3>
            <b-form-group
              label="เพิ่มข้อความประกาศ"
              label-for="anounce"
            >
              <b-form-textarea
                id="anounce"
                v-model="agent.announce"
                rows="5"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fab fa-ethereum" />เติมมือต่อครั้ง</h3>
            <b-form-group
              label="แอดมินเติมมือสูงสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.max_manual_dep_level1"
                type="number"
                placeholder="เติมมือสูงสุด"
              />
            </b-form-group>
            <b-form-group
              label="ซุปเปอร์แอดมินเติมมือสูงสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.max_manual_dep_level2"
                type="number"
                placeholder="เติมมือสูงสุด"
              />
            </b-form-group>

          </b-card>
        </b-col>
      </b-row>

      <b-modal
        ref="my-modal-kbank"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitGenKbank()"
      >
        <b-form-group>
          <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
          <b-input-group>
            <b-form-input
              v-model="acc_name_kbank"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="เลขบัญชี (ไม่ต้องมี - )"
          label-for="acc_kbank"
        >
          <b-form-input
            id="acc_kbank"
            v-model="acc_kbank"
            placeholder="เลขบัญชีถอนกสิกร"
          />
        </b-form-group>

        <b-form-group
          label="PIN (รหัส App K Plus)"
          label-for="pin_kbank"
        >
          <b-form-input
            id="pin_kbank"
            v-model="pin_kbank"
            placeholder="รหัส App K Plus"
            type="Number"
            maxlength="6"
          />
        </b-form-group>

        <b-form-group
          label="ข้อมูลที่ Gen ผ่าน App"
          label-for="gen_kbank"
        >
          <b-form-textarea
            id="gen_kbank"
            v-model="gen_kbank"
            placeholder="ข้อมูลที่ Gen ผ่าน App"
            type="text"
          />
        </b-form-group>
      </b-modal>
      <b-modal
        ref="modal-kbank-api"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitGenKbankApi()"
      >
        <b-form-group>
          <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
          <b-input-group>
            <b-form-input
              v-model="KbankApi.acc_name"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="เลขบัญชี (ไม่ต้องมี - )"
          label-for="acc_kbank"
        >
          <b-form-input
            id="acc_kbank"
            v-model="KbankApi.acc_no"
            placeholder="เลขบัญชีถอนกสิกร"
          />
        </b-form-group>

        <b-form-group
          label="เบอร์โทร (ไม่ต้องมี - )"
          label-for="Kbiz_phone"
        >
          <b-form-input
            id="Kbiz_phone"
            v-model="KbankApi.phone"
            placeholder="เบอร์โทร"
          />
        </b-form-group>

        <b-form-group
          label="Kbiz username"
          label-for="Kbiz_Username"
        >
          <b-form-input
            id="Kbiz_Username"
            v-model="KbankApi.username"
            placeholder="Kbiz Username"
          />
        </b-form-group>

        <b-form-group
          label="Kbiz password"
          label-for="Kbiz_password"
        >
          <b-form-input
            id="Kbiz_password"
            v-model="KbankApi.password"
            placeholder="Kbiz password"
          />
        </b-form-group>

      </b-modal>

      <b-modal
        ref="my-modal"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="GetDeviceIdScb()"
      >
        <div v-if="step == 1">
          <b-form-group>
            <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
            <b-input-group>
              <b-form-input
                v-model="acc_name_scb"
                type="text"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">เลขบัตรประชาชน <span class="text-danger">* ไม่มี - </span></label>
            <b-input-group
              prepend="ไม่ต้องมี -"
            >
              <b-form-input
                v-model="cardId"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">วัน / เดือน / ปีเกิด (คศ.) <span class="text-danger">*(ตัวอย่าง 1999-01-01)</span></label>
            <b-input-group
              prepend="1999-01-01"
            >
              <b-form-input
                v-model="dateOfBirth"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">เบอร์โทร <span class="text-danger">* ไม่มี -</span></label>
            <b-input-group
              prepend="ไม่ต้องมี -"
            >
              <b-form-input
                v-model="MobilePhoneNo"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="step == 2">
          <b-form-group>
            <label for="dividend"> เลขที่บัญชี <span class="text-danger">* ไม่มี -</span></label>
            <b-input-group
              prepend=""
            >
              <b-form-input
                v-model="acc_no"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend"> PIN <span class="text-danger">*</span></label>
            <b-input-group
              prepend=""
            >
              <b-form-input
                v-model="pin"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend"> OTP <span class="text-danger">*  (ไม่เกิน 5 นาที)</span></label>
            <b-input-group
              prepend=""
            >
              <b-form-input
                v-model="otp"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </b-modal>

      <b-modal
        ref="money-rocker"
        hide-footer
        hide-header
        title="โยกเงินอัตโนมัติ"
        centered
      >
        <div class="text-center">
          <img
            src="confirm.png"
            alt="confirm"
            height="80"
          >
          <h5>{{ ShowCheckBank.bankName }}</h5>
          <h4>{{ ShowCheckBank.acc }}</h4>
          <h5>{{ ShowCheckBank.name }}</h5>

          <b-button
            variant="gradient-primary"
            size="sm"
            pill
            @click="hide()"
          >
            ปิดหน้าต่าง
          </b-button>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTab,
  BFormCheckbox,
  BTabs,
  BFormTextarea,
  BModal,
  BOverlay, BIconController,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BFormTextarea,
    BOverlay,
    BIconController,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      agents: {
        network: 'BNB Smart Chain (BEP20)',
      },
      service_type_kbank: null,
      acc_name_scb: '',
      acc_name_kbank: '',
      acc_kbank: '',
      pin_kbank: '',
      gen_kbank: '',
      step: 1,
      cardId: '',
      dateOfBirth: '',
      MobilePhoneNo: '',
      deviceId: '',
      Auth: '',
      ref: '',
      tokenUUID: '',
      pin: '',
      acc_no: '',
      bank: null,
      show: false,
      banklist: [],
      banklist2: [],
      agent: {},
      selected: { title: '' },
      option: [{ title: 'Square' }],
      options: {
        customDelimiter: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
      autolist: [
        { text: 'ระบบถอน scb', value: 'scb' },
        { text: 'ระบบถอน kbank', value: 'kbank' },
      ],
      AutoData: {
        prefix: '',
        move_dep1_status: 0,
        move_dep1_amount: 0,
        move_dep1_bank: '',
        move_dep1_acc: '',
        move_dep2_status: 0,
        move_dep2_amount: 0,
        move_dep2_bank: '',
        move_dep2_acc: '',
        move_dep3_status: 0,
        move_dep3_amount: 0,
        move_dep3_bank: '',
        move_dep3_acc: '',
        move_dep4_status: 0,
        move_dep4_amount: 0,
        move_dep4_bank: '',
        move_dep4_acc: '',
        move_crypto_status: 0,
        move_crypto_amount: '',
        move_crypto_acc: '',
      },
      crypto_usdt: 0,
      ShowCheckBank: {
        bankName: '',
        acc: '',
        name: '',
      },
      wit_scb_list: [],
      wit_scb_id: null,
      KbankApi: {
        acc_no: '',
        acc_name: '',
        username: '',
        password: '',
        phone: '',
        status: 0,
      },
    }
  },
  async mounted() {
    this.getAgent()
    this.getWitScbList()
    // this.getMovemoney()
    this.Get_bank()
  },
  methods: {
    addAdr() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    hide() {
      this.$refs['money-rocker'].hide()
    },
    SubmitGenKbank() {
      this.show = true
      if (this.service_type_kbank === 'deposit') {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
        var path = '/kbankSetting/deposit'
      } else {
        // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
        var path = '/kbankSetting/withdraw'
      }
      const obj = {
        accountNo: this.acc_kbank,
        pin: this.pin_kbank,
        stateFile: this.gen_kbank,
        acc_name_kbank: this.acc_name_kbank,
      }
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post(path, obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.Success('เชื่อมข้อมูลสำเร็จ')
          this.acc_name_kbank = ''
          this.acc_kbank = ''
          this.pin_kbank = ''
          this.gen_kbank = ''
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.SwalError('เชื่อมข้อมูลไม่สำเร็จ')
          this.show = false
        })
    },
    SubmitGenKbankApi() {
      this.show = true
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post('/agent/update/withdraw/kbiz', this.KbankApi)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.Success('เชื่อมข้อมูลสำเร็จ')
          // this.KbankApi = {
          //   acc_no: '',
          //   acc_name: '',
          //   username: '',
          //   password: '',
          //   phone: '',
          // }
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.SwalError('เชื่อมข้อมูลไม่สำเร็จ')
          this.show = false
        })
    },
    GetDeviceIdScb() {
      this.show = true
      if (this.step === 1) {
        const obj = {
          cardId: this.cardId,
          dateOfBirth: this.dateOfBirth,
          MobilePhoneNo: this.MobilePhoneNo,
        }
        this.$http
          .post('/deviceId/scb/v2', obj)
          .then(response => {
            // console.log(response.data)
            if (response.data.Auth) {
              this.show = false
              this.step = 2
              this.deviceId = response.data.deviceId
              this.Auth = response.data.Auth
              this.ref = response.data.ref
              this.tokenUUID = response.data.tokenUUID
              this.showModal(this.bank)
            }
          })
          .catch(error => {
            this.show = false
            this.SwalError(error.response.data.message.statusdesc)
            this.showModal(this.bank)
          })
      } else {
        const obj = {
          acc_name_scb: this.acc_name_scb,
          acc_no: this.acc_no,
          bank: this.bank,
          phone: this.MobilePhoneNo,
          pin: this.pin,
          otp: this.otp,
          deviceId: this.deviceId,
          Auth: this.Auth,
          ref: this.ref,
          tokenUUID: this.tokenUUID,
        }
        this.$http
          .post('/ConfirmOtp/scb/v2', obj)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            // console.log(response.data)
            this.Success('เพิ่มข้อมูลโยกเงินสำเร็จ')
            this.acc_name_scb = ''
            this.deviceId = ''
            this.Auth = ''
            this.ref = ''
            this.tokenUUID = ''
            this.MobilePhoneNo = ''
            this.pin = ''
            this.otp = ''
            this.cardId = ''
            this.dateOfBirth = ''
            this.acc_no = ''
            this.show = false
            this.step = 1
          })
          .catch(error => {
            this.step = 1
            this.SwalError(error.response.data.message)
            this.showModal()
            this.show = false
          })
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    showModalkbank() {
      this.$refs['my-modal-kbank'].show()
    },
    hideModalkbank() {
      this.$refs['my-modal-kbank'].hide()
    },
    showModalkbankApi() {
      this.$refs['modal-kbank-api'].show()
    },
    Get_bank() {
      this.$http
        .get('/bank/list/kr')
        .then(response => {
          this.banklist = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    async getAgent() {
      this.show = true
      this.$http
        .get('/agent/show')
        .then(response => {
          this.getKbiz()
          // console.log('agent')
          this.agent = response.data
          this.getMovemoney()
          this.switch(response.data)

          // console.log(response.data)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async getMovemoney() {
      this.show = true
      this.$http
        .get('/agent/show/auto')
        .then(response => {
          this.AutoData = response.data
          // console.log('auto')
          this.wit_scb_id = this.AutoData.wit_scb_id
          if (this.AutoData.crypto_address) {
            this.getCryptoBalance()
          }
          // console.log(this.agent)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async getWitScbList() {
      this.show = true
      this.$http
        .get('/setting/withdrawscb/index')
        .then(response => {
          this.wit_scb_list = response.data
          this.wit_scb_list.forEach((element, index) => {
            this.wit_scb_list[index].text = `ชื่อ: ${element.accname} บัญชี: ${element.accno}`
          })
          // console.log(response.data)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    GenCryptoWallet() {
      this.show = true
      this.$http
        .get('/crypto/createwallet')
        .then(response => {
          this.AutoData.crypto_address = response.data
          this.getCryptoBalance()
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async confirm(banknumber) {
      try {
        this.show = true
        this.AutoData.bank_number = banknumber
        const { data: res } = await this.$http.post('/agent/update/movemoney', this.AutoData)
        // console.log(res.status)
        if (res.status) {
          this.show = false
          this.Success('บันทึกข้อมูลสำเร็จ')
        } else {
          this.show = false
          this.SwalError('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        this.show = false
        this.SwalError('เกิดข้อผิดพลาด')
      }
    },
    async UpdateStatusCrypto() {
      try {
        this.show = true
        const { data: res } = await this.$http.post('/agent/update/crypto', this.agent)
        // console.log(res.status)
        if (res.status) {
          this.show = false
          this.Success('บันทึกข้อมูลสำเร็จ')
        } else {
          this.show = false
          this.SwalError('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        this.show = false
        this.SwalError('เกิดข้อผิดพลาด')
      }
    },
    getCryptoBalance() {
      const params = {
        walletAddress: this.AutoData.crypto_address,
      }
      this.$http
        .get('/crypto/getbalance', { params })
        .then(response => {
          this.crypto_usdt = response.data.usdt
        })
        .catch(error => console.log(error))
    },
    async checkAcc(banknumber) {
      this.show = true
      if (banknumber === 1) {
        if (!this.agent.dip_bank_accno) {
          this.show = false
          this.$swal('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลบัญชี', 'error')
          return
        }
        const Facc = this.agent.dip_bank_accno.replace(/-/g, '')
        const Tacc = this.AutoData.move_dep1_acc.replace(/-/g, '')
        const Tcode = this.AutoData.move_dep1_bank
        const amount = '1.00'
        const { prefix } = this.AutoData
        const banktype = 'dip1'
        await this.CheckAccNameScb(Facc, Tacc, Tcode, amount, prefix, banktype)
      } else if (banknumber === 2) {
        const Tacc = this.AutoData.move_dep2_acc.replace(/-/g, '')
        const Tcode = this.AutoData.move_dep2_bank
        const amount = '1.00'
        const { prefix } = this.AutoData
        const banktype = 'deposit'
        await this.CheckAccNameKbank(Tcode, Tacc, amount, prefix, banktype)
      } else if (banknumber === 3) {
        if (!this.agent.dip_bank3_accno) {
          this.show = false
          this.$swal('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลบัญชี', 'error')
          return
        }
        const Facc = this.agent.dip_bank3_accno.replace(/-/g, '')
        const Tacc = this.AutoData.move_dep3_acc.replace(/-/g, '')
        const Tcode = this.AutoData.move_dep3_bank
        const amount = '1.00'
        const { prefix } = this.AutoData
        const banktype = 'dip3'
        await this.CheckAccNameScb(Facc, Tacc, Tcode, amount, prefix, banktype)
      }
    },
    async CheckAccNameScb(Frombankcode, accno, Tobankcode, amount, prefix, banktype) {
      const obj = {
        facc: Frombankcode,
        tacc: accno,
        tcode: Tobankcode,
        amount,
        prefix,
        banktype,
      }
      this.$http
        .post('movemoney/CheckAccNameScb', obj)
        .then(response => {
          this.show = false
          if (response.data.status) {
            this.$refs['money-rocker'].show()
            const Bank = response.data.data
            this.ShowCheckBank.acc = Bank.accountTo
            this.ShowCheckBank.name = Bank.accountToDisplayName
            this.ShowCheckBank.bankName = this.banklist.find(bank => bank.bankcode === Tobankcode).name
          } else {
            this.$swal('เกิดข้อผิดพลาด', response.data.msg, 'error')
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    async CheckAccNameKbank(bankcode, accno, amount, prefix, type) {
      const obj = {
        toBankCode: bankcode,
        toAccount: accno,
        amount,
        prefix,
        type,
      }
      this.$http
        .post('movemoney/CheckAccNameKbank', obj)
        .then(response => {
          this.show = false
          console.log(response.data)
          if (response.data.status) {
            this.$refs['money-rocker'].show()
            const Bank = response.data.data
            this.ShowCheckBank.acc = Bank.toAccountNo
            this.ShowCheckBank.name = Bank.toAccountName
            this.ShowCheckBank.bankName = Bank.bankName
          } else {
            this.$swal('เกิดข้อผิดพลาด', response.data.msg, 'error')
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    getKbiz() {
      this.$http
        .get('/agent/show/kbiz')
        .then(response => {
          if (response.data.AutoData) {
            const Data = response.data.AutoData
            this.agent.dip_bank2_username = Data.dip_bank2_username
            this.agent.dip_bank2_password = Data.dip_bank2_password
            this.agent.dip_bank4_username = Data.dip_bank4_username
            this.agent.dip_bank4_password = Data.dip_bank4_password

            this.KbankApi.acc_no = Data.wit_bank1_accno
            this.KbankApi.acc_name = Data.wit_bank1_accname
            this.KbankApi.username = Data.wit_bank1_username
            this.KbankApi.password = Data.wit_bank1_password
            this.KbankApi.phone = Data.wit_bank1_sms
          }
          if (response.data.AutoWitBot) {
            const Data = response.data.AutoWitBot
            this.KbankApi.status = Data.status
          }
          this.show = false
        })
    },
    switch(data) {
      if (data.status_dip === '1') {
        this.agent.status_dip = true
      }
      if (data.status_wit === '1') {
        this.agent.status_wit = true
      }
      if (data.status_auto_dip === '1') {
        this.agent.status_auto_dip = true
      }
      if (data.status_auto_wit === '1') {
        this.agent.status_auto_wit = true
      }
      if (data.dep_sms === '1' || data.dep_sms === 1) {
        this.agent.dep_sms = true
      }
      if (data.dep_connect === '1' || data.dep_connect === 1) {
        this.agent.dep_connect = true
      }
      if (data.maintenance === '1') {
        this.agent.maintenance = true
      }
      // eslint-disable-next-line eqeqeq
      if (data.clearuser == 1) {
        this.agent.clearuser = true
      }
      if (data.checkbank1 === 1) {
        this.agent.checkbank1 = true
      }
      if (data.checkbank2 === 1) {
        this.agent.checkbank2 = true
      }
      if (data.checkbank3 === 1) {
        this.agent.checkbank3 = true
      }
      if (data.checkbank4 === 1) {
        this.agent.checkbank4 = true
      }
      if (data.checktruewallet === 1) {
        this.agent.checktruewallet = true
      }
      if (data.alarm === 1) {
        this.agent.alarm = true
      }
      if (data.status_checkname === 1) {
        this.agent.status_checkname = true
      }
    },
    submit() {
      if (this.agent.id) {
        if (this.agent.alarm === true) {
          this.agent.alarm = 1
        }
        if (this.agent.status_checkname === true) {
          this.agent.status_checkname = 1
        }
        if (this.wit_scb_id) {
          this.agent.wit_scb_id = this.wit_scb_id
        }
        this.$http
          .post('/agent/update', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('แก้ไขข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('/agent/store', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('เพิ่มข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.card-credit {
  background: #e9e9e9;
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.g-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  border-radius: 0.25rem;
  padding: 2.35rem;
  margin-bottom: 1rem;
}
.g-status.success {
  background: linear-gradient(to right, #3dc92a, #00770a);
}
.g-status.error {
  background: linear-gradient(to right, #ff5b5b, #c70000);
}
.g-status.warning {
  background: linear-gradient(to right, #ffb800, #ff8a00);
}
.g-status.info {
  background: linear-gradient(to right, #00b0ff, #0078ff);
}
.g-status.secondary {
  background: linear-gradient(to right, #797979, #424547);
}

i {
  color: #2e5d92;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
